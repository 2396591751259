<template>
  <Section>
    
    <FadeIn>
      
      <Container>
        <div class="lg:flex ">
          <div class="lg:w-[50%]">
            <h2 class="text-section-title">{{ content.title }}</h2>
            <div
            v-if="content.subtitle"
            class="text-base mt-4" 
            v-interpolation 
            v-html="content.subtitle"
            ></div>
          </div>
          <div class="hidden lg:block lg:w-[50%]">
            <div class="flex justify-end h-full items-end pb-3">
              <button 
              @click="prev()" 
              class="bg-[#8293f8] border-2 border-[#5a6ded] rounded w-12 h-8 flex items-center justify-center text-white"
              role="button"
              aria-label="Previous item"
              >
                <Icon name="heroicons:arrow-left-20-solid" class="w-8 h-6" />
              </button>
              <button 
              @click="next()" 
              class="ml-4 bg-[#8293f8] border-2 border-[#5a6ded] rounded w-12 h-8 flex items-center justify-center text-white"
              role="button"
              aria-label="Next item"
              >
                <Icon name="heroicons:arrow-right-20-solid" class="w-8 h-6" />
              </button>
            </div>
          </div>
        </div>
      </Container>

      <ClientOnly>
        <swiper
        :freeMode="free"
        :slides-per-view="perView"
        :space-between="between"
        :slides-offset-before="offsetLeft"
        :slides-offset-after="30"
        :navigation="true"
        :modules="[Navigation]"
        class="mt-4 md:mt-8"
        >
          <swiper-slide v-for="(item, index) in industries">
            <NuxtLink
            :to="item.url"
            class="flow-root rounded-xl relative flex flex-col justify-between flex-1 aspect-square"
            >
              <img
              class="rounded-xl aspect-square w-full h-full object-cover relative z-1 transition-all duration-300 hover:brightness-75"
              :src="useCdn(item.img_id, item.img_filename)+'?width=600'" 
              :alt="`Use cases for Elker: ${item.title}`"
              width="600" 
              height="600"
              loading="lazy"
              >
              <div 
              class="mt-auto absolute w-[calc(100%-2em)] bottom-3 md:bottom-4 left-3 md:left-4 z-3 font-bold tracking-tight text-white text-sm md:text-base leading-[1.2]">
                {{ item.title }}
              </div>
            </NuxtLink>
          </swiper-slide>
        </swiper>
      </ClientOnly>
    </FadeIn>

  </Section>
</template>

<script setup>
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  sectionIndex: {
    type: Number
  }
})

const offsetLeft = ref(50)
const between = ref(10)
const perView = ref(1.2)
const free = ref(true)

const prev = () => {
  if (!process.client) return
  const clickedElement = event.currentTarget
  const parentSection = clickedElement.closest('section')
  if (!parentSection) return
  const swiperButtonNext = parentSection.querySelector('.swiper-button-prev')
  if (!swiperButtonNext) return
  swiperButtonNext.click()
}

const next = () => {
  if (!process.client) return
  const clickedElement = event.currentTarget
  const parentSection = clickedElement.closest('section')
  if (!parentSection) return
  const swiperButtonNext = parentSection.querySelector('.swiper-button-next')
  if (!swiperButtonNext) return
  swiperButtonNext.click()
}

const carouselSize = () => {
  const windowW = window.innerWidth
  if (windowW > 1800) {
    between.value = 20
    offsetLeft.value = (windowW - 1400) / 2 + 35
    perView.value = 5.5
    free.value = false
  } else if (windowW > 1440) {
    between.value = 20
    offsetLeft.value = (windowW - 1400) / 2 + 35
    perView.value = 4.2
    free.value = false
  } else if (windowW > 1300) {
    between.value = 15
    offsetLeft.value = (windowW - 1300) / 2 + 30
    perView.value = 3.6
    free.value = false
  } else if (windowW > 1024) {
    between.value = 15
    offsetLeft.value = 35
    perView.value = 3.2
    free.value = false
  } else if (windowW > 768) {
    between.value = 15
    offsetLeft.value = 35
    perView.value = 2.8
    free.value = false
  } else if (windowW > 600) {
    between.value = 15
    offsetLeft.value = 33
    perView.value = 1.9
    free.value = true
  } else if (windowW > 413) {
    between.value = 15
    offsetLeft.value = 33
    perView.value = 1.5
    free.value = true
  } else {
    between.value = 10
    offsetLeft.value = 15
    perView.value = 1.2
    free.value = true
  }
}

onMounted(() => {
  if (process.client) {
    carouselSize()
    window.addEventListener('resize', () => {
      if (process.client) {
        carouselSize()
      }
    })
  }
})

const industries = [
  {
    title: 'Businesses',
    url: '/use-cases/businesses',
    img_id: '74907674-23d5-4344-a2b7-4310163813f6',
    img_filename: 'use-cases-businesses.png'
  },
  {
    title: 'Public sector',
    url: '/use-cases/government',
    img_id: 'c85137b7-db3c-4b74-b909-0898b86f3e6b',
    img_filename: 'use-cases-public-sector.png'
  },
  {
    title: 'Universities',
    url: '/use-cases/universities',
    img_id: '43541d4d-9ed0-4154-8078-32c453278432',
    img_filename: 'use-cases-universities.png'
  },
  // {
  //   title: 'Healthcare',
  //   url: '/use-cases/aged-care-disability-services',
  //   img_id: 'f951b1ac-2d84-455e-9864-85fea88cc024',
  //   img_filename: 'uses-cases-healthcare.png'
  // },
  {
    title: 'Disability support',
    url: '/use-cases/aged-care-disability-services',
    img_id: 'c80791fd-c562-43b5-81a1-79c2b1b95943',
    img_filename: 'use-cases-disability-support.png'
  },
  {
    title: 'Aged care',
    url: '/use-cases/aged-care-disability-services',
    img_id: 'fb7e1615-25ee-4cd4-b074-eeff6b42230b',
    img_filename: 'use-cases-aged-care.png'
  },
  {
    title: 'Schools',
    url: '/use-cases/schools',
    img_id: 'ce578447-9a49-40fc-a33c-dd65d8cf4cb2',
    img_filename: 'use-cases-schools.png'
  },
  {
    title: 'Peak bodies & associations',
    url: '/use-cases/peak-bodies',
    img_id: '94bd170b-86f8-46de-b49d-2be5e04e9f8a',
    img_filename: 'use-cases-peak-bodies.png'
  },
]
</script>